<template>
  <font-awesome-layers
    fa-fw
    class="result-table"
  >
    <font-awesome-icon
      icon="fa-thin fa-circle"
      :class="variant"
      size="2x"
    />
    <font-awesome-icon
      icon="fa-solid fa-circle"
      transform="shrink-4"
      :class="variant"
      size="2x"
    />
  </font-awesome-layers>
  <!-- <font-awesome-icon
    icon="fa-solid fa-circle"
    size="2x"
    :class="variant"
  /> -->
</template>

<script>
export default {
  name: 'priority',
  props: ['priority'],
  data() {
    return {};
  },
  computed: {
    value() {
      switch (this.priority) {
        case 'LONGTERM':
          return this.$tc('priority.longTerm.value');
        case 'MEDIUMTERM':
          return this.$t('priority.mediumTerm.value');
        case 'SHORTTERM':
          return this.$t('priority.shortTerm.value');
        case 'URGENT':
          return this.$t('priority.urgent.value');
        default:
          break;
      }
      return '';
    },
    variant() {
      switch (this.priority) {
        case 'LONGTERM':
          return 'longterm';
        case 'MEDIUMTERM':
          return 'mediumterm';
        case 'SHORTTERM':
          return 'shortterm';
        case 'URGENT':
          return 'urgent';
        default:
          break;
      }
      return '';
    },
  },
};
</script>
<style scoped>
.longterm {
    color: blue !important;
}
.mediumterm {
    color: yellow !important;
}
.shortterm {
    color: orange !important;
}
.urgent {
    color: red !important;
}
.result-table {
  animation: pop .3s linear;
}
</style>